import React, { useEffect, useRef } from "react";
import { FC } from "react";
import "../style/nullStyle.css";
import Tabel from "./Tabel/Tabel";
import { tabelColumns } from "./Tabel/TabelColumns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { optionsMainTheme } from "../style/TabelStyle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUploadFile } from "../hoocks/useUploadeFile";
import { useFetchData } from "../hoocks/useFetchData";
import fileServe from "../files/vip.csv";

const App: FC = () => {
  const refButton = useRef<HTMLInputElement>(null);

  const { file, setFile, handleFile } = useUploadFile();
  const { setError, error, isLoading, data } = useFetchData(file, setFile);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
  }, [error]);

  const hendleClickPC = () => {
    if (refButton.current !== null) refButton.current.click();
  };

  const hendleClickServer = () => {
    handleFile(fileServe, "server");
  };

  const mainTheme = createTheme(optionsMainTheme);

  return (
    <div style={{ padding: "10px", background: "#e9e9e9", height: "100vh" }}>
      <div>
        <input
          type={"file"}
          name={"file"}
          accept={".csv"}
          onChange={(e) => handleFile(e, "pc")}
          style={{ display: "none" }}
          ref={refButton}
        />
      </div>
      <ThemeProvider theme={mainTheme}>
        <Tabel
          isLoading={isLoading}
          title={"Проверка сигнала"}
          columns={tabelColumns()}
          data={data}
          onClickPC={hendleClickPC}
          onClickServer={hendleClickServer}
        />
      </ThemeProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
export default App;