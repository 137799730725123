import React from "react";
import { IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IToolbarProps } from '../../interfaces/interface';

const TabelToolbar = ({ onClickServer, onClickPC }: IToolbarProps) => {
  return (
    <>
      <IconButton title="Добавить" onClick={onClickPC}>
        <UploadFileIcon
          color={"action"}
          style={{ width: "32px", height: "32px" }}
        />
      </IconButton>
      <IconButton title="Добавить" onClick={onClickServer}>
        <CloudUploadIcon
          color={"action"}
          style={{ width: "32px", height: "32px" }}
        />
      </IconButton>
    </>
  );
};
export default TabelToolbar;
