import React, { FC } from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import TabelToolbar from "./TabelToolbar";
import Loader from "../Loader";
import { ITabel } from "../../interfaces/interface";

const Tabel: FC<ITabel> = ({
  title,
  columns,
  data,
  isLoading,
  children,
  onClickPC,
  onClickServer,
  expandableRows = false,
}) => {
  const options: MUIDataTableOptions = {
    pagination: false,
    serverSide: false,
    viewColumns: false,
    search: false,
    filter: false,
    download: false,
    print: false,
    expandableRows: expandableRows,
    fixedHeader: true,
    fixedSelectColumn: true,
    confirmFilters: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 100],
    customToolbar: () => (
      <TabelToolbar onClickPC={onClickPC} onClickServer={onClickServer} />
    ),
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              marginLeft: "-40px",
              fontFamily: "Roboto, Arial, sans-serif",
              fontSize: "15px",
            }}
          >
            Загрузите csv файл
          </div>
        ),
      },
    },
    renderExpandableRow: (rowData, rowMeta) =>
      React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>, { rowMeta })
      ),
  };

  return (
    <>
      <div>
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
};
export default Tabel;
