import react, { useState } from "react";
import Papa, { ParseResult } from "papaparse";
import { IFile } from "../interfaces/interface";

export const useUploadFile = () => {
  const [file, setFile] = useState<IFile[]>([]);

  const handleFile = async (f: any, type: string) => {
    const data = await fetch(f)
      .then((r) => r.text())
      .then((text) => {
        return text;
      });

    Papa.parse(type === "pc" ? f.target.files[0] : data, {
      header: true,
      skipEmptyLines: true,
      complete: function (result: ParseResult<IFile>) {
        setFile(result.data);
        return result.data;
      },
    });

    if (type === "pc") f.target.value = null;
  };
  return { file, setFile, handleFile };
};
