import react, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useSound from "use-sound";
import tetbesoobhenieChelovek from "../sounds/tetbesoobhenieChelovek.mp3";
import { IData, IFile } from "../interfaces/interface";

const HTTP = `http://${process.env.REACT_APP_API}/snmp_api/`;

export const useFetchData = (
  file: any,
  setFile: React.Dispatch<React.SetStateAction<IFile[]>>
) => {
  const [data, setData] = useState<IData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [play] = useSound(tetbesoobhenieChelovek);

  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  const chechData = (data: IData[]) => {
    return data.find((element: IData) => element.signal === "0.00");
  };

  useEffect(() => {
    if (file.length > 0) {
      setIsLoading(true);
      console.log("Запрос поcле загрузки файла!");
      fetchData();
    }
  }, [file]);

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      if (data.length > 0) {
        console.log("Мониторинг!");
        fetchData();
      }
    }, 60000*10);

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    };
  }, [data]);

  useEffect(() => {
    if (data.length > 0 || error !== "") {
      setIsLoading(false);
    }
  }, [data]);

  const fetchData = async () => {
    try {
      const { data } = await axios.post(HTTP, file);

      const checked = chechData(data);
      if (checked) {
        play();
      }

      setData(data);
      return true;
    } catch (error: any) {
      setError(error.response.data.message);
      setData([]);
      setFile([]);
    }
  };
  return { setError, error, isLoading, data };
};
