import { ThemeOptions } from "@mui/material/styles/createTheme";

export const optionsMainTheme: ThemeOptions = {
  components: {
    MuiTableCell: {
      //для всех ячеек таблиц
      styleOverrides: {
        root: {
          padding: "15px",
        },
      },
    },

    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          marginLeft: "14px",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          marginLeft: "40px",
          fontSize: "20px",
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          overflowY: "auto",
        },
      },
    },

    MUIDataTablePagination: {
      styleOverrides: {
        root: {
          p: {
            marginTop: "initial",
            marginBottom: "initial",
          },
        },
      },
    },

    MUIDataTableToolbar: {
      styleOverrides: {
        //для всех тулбаров таблиц
        root: {
          padding: "5px",
        },
        filterPaper: {
          width: "460px",
        },
      },
    },
  },

  typography: {
    //шрифты проекта
    fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
  },
};
