import React from "react";
import { MUIDataTableColumn } from "mui-datatables";

export const tabelColumns = () => {
  const renderColor = (num: number) => {
    if (num === 0.0) {
      return "gray";
    } else if (-12.0 >= num && num >= -24.99) {
      return "green";
    } else if (-25.0 >= num && num >= -26.99) {
      return "orange";
    } else if (-27.0 >= num) {
      return "red";
    }
  };

  const columns: MUIDataTableColumn[] = [
    { name: "id", label: "#" },
    { name: "branch", label: "Ветка" },
    { name: "city", label: "Город" },
    { name: "name", label: "Название" },
    {
      name: "signal",
      label: "Сигнал",
      options: {
        customBodyRender: (data, type, row) => {
          return (
            <mark
              style={{
                color: "white",
                backgroundColor: renderColor(Number(data)),
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              {data}
            </mark>
          );
        },
      },
    },
  ];

  return columns;
};
