import React, { FC } from "react";
import { PacmanLoader } from "react-spinners";

const Loader: FC = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "-40px",
      }}
    >
      <PacmanLoader color="rgb(117,117,117)" />
    </div>
  );
};
export default Loader;
